<template>
  <div class="me-2">
    <button
      class="btn btn-sm btn-icon btn-bg-light btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
      ref="filterButton"
    >
      <el-tooltip class="item" effect="dark" :content="$t('titles.filter')" placement="bottom">
        <span class="svg-icon svg-icon-2 svg-icon-gray-500">
          <Icon name="Filter.svg"/>
        </span>
      </el-tooltip>
    </button>
    <div
      class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
      data-kt-menu="true"
      v-loading="filterLoading"
    >
      <div class="px-7 py-5">
        <div class="fs-5 text-dark fw-bolder">Filter</div>
      </div>
      <div class="separator border-gray-200"></div>

      <div v-if="filterData.status" class="px-7 py-5">
        <template v-for="filter in filterData.filters" :key="filter.options">
          <div class="mb-10">
            <label class="form-label fw-bold">{{ filter.label }}:</label>
            <div>
              <el-select
                v-model="filter.defaultValue"
                multiple
                collapse-tags
                filterable 
                placeholder="Choose"
                @change="updateFilter(filter.id, filter.defaultValue)"
                style="width:100%"
                clearable
              >
                <el-option
                  v-for="item in filter.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span
                    style="
                      float: right;
                      color: var(--el-text-color-secondary);
                      font-size: 13px;
                    "
                  >
                    {{ item.count }}
                  </span>
                </el-option>
              </el-select>
            </div>
          </div>
        </template>
        <div class="d-flex justify-content-end">
          <button
            type="reset"
            class="btn btn-sm btn-white btn-active-light-primary me-2"
            data-kt-menu-dismiss="true"
            @click="resetFilter()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useStore } from "vuex";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import useComponentFilter from '@/composables/v2/useComponentFilter';
import Icon from "@/components/icons/Icon.vue";

export default defineComponent({
  name: "",
  props: {
    currentData: String,
    currentSetting: String,
    dispatchActions: Object
  },
  components: {
    Icon,
  },
  emits: ["unique", "reload"],

  setup(props, {emit}) {
    const store = useStore();
    const { initFilter, filterData, updateFilter, resetFilter, filterLoading } = useComponentFilter();

    initFilter(props)

    const filterButton = ref(null);

    watch(() => store.getters[props.currentData], (value) => {
        initFilter(props)
      }
    )

    onMounted(() => {
      MenuComponent.reinitialization();
    })

    return {
      filterButton,
      filterData,
      updateFilter,
      resetFilter,
      filterLoading
    };
  }
});
</script>