
import { defineComponent, computed, ref, onMounted, nextTick, watch } from "vue";
import useComponentv2 from '@/composables/v2/useComponent';
import FormTypes from '@/components/forms/v2/Types.vue';
import ModalLanguage from "@/components/modals/default/v2/LanguageModal.vue";
import useLanguage from '@/composables/v2/useLanguage';
import { Modal } from "bootstrap";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "",
  components: {
    FormTypes,
    ModalLanguage,
  },
  props: {
    componentName: String,
    componentTitle: String,
    displayMethod: String,
    componentType: String,
    dispatchActions: Object,
    componentEmit: Object,
    componentReload: Boolean,
    componentRedirect: Object,
    currentData: String,
    componentFilter: Boolean,
    modalId: String,
    modalIdPrevious: Object,
    currentId: String,
    routeId: String,
  },
  emits: ["reload"],
  setup(props, { emit }) {
    const { initComponent, componentData, componentLoading, updateComponent, submitButtonComponent, submitLoadingComponent, saveComponent, componentStatus, modalRef, reloadComponent } = useComponentv2();
    const { renderAmount, fieldName, fieldValue, fieldType, initLanguageModal } = useLanguage();

    const { t } = useI18n();

    initComponent({
      componentName: props.componentName, 
      displayMethod: props.displayMethod, /* new or existing */
      componentType: props.componentType, /* form or table */
      dispatchActions: props.dispatchActions,
      componentEmit: props.componentEmit,
      componentReload: props.componentReload,
      componentRedirect: props.componentRedirect,
      currentData: props.currentData,
      componentFilter: false, 
      currentId: props.currentId,
      routeId: props.routeId,
      modalId: props.modalId,
    });

    onMounted(() => {
      if (props.displayMethod == 'existing') {
        const modal = document.getElementById(props.modalId!)
        const element = Modal.getOrCreateInstance(modal)
        element.show()
      }
    });

    return {
      componentData,
      componentLoading,
      componentStatus,
      updateComponent,
      submitButtonComponent,
      submitLoadingComponent,
      saveComponent,
      modalRef,
      renderAmount, fieldName, fieldValue, fieldType, initLanguageModal,
      reloadComponent,
      t
    };
  }
});
