import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Mutations } from "@/store/enums/StoreEnums";

export default function useComponentFilter() {
  const store = useStore()
  const { emit } = getCurrentInstance() as any;

  const filterInit = ref();
  const filterData = ref({}) as any
  const filterString = ref({}) as any
  const filterLoading = ref(false)

  const getComponentFilter = computed(() => {
    return store.getters[filterInit.value.currentData].filters;
  });

  const getSetting = (name) => {
    return store.getters.getSetting(name);
  };

  const getSetting1 = async (name, getter) => {
    await store.dispatch(Actions[name])
    return store.getters[getter].items;
  }

  const initFilter = async (props) => {
    filterInit.value = props;
    //store.commit(Mutations[filterInit.value.dispatchActions.save], {string: '', options: null});
    filterData.value.filters = await createFilters();
    filterData.value.status = true;
  }

  const createFilterOptions = async (id, items) => {
    const filterOptions = [] as any

    let settingPath;
    let settingName;
    let apiEndpoint;
    let getData;

    let options = [] as any
    let propName;

    if (id == 'sale_status') {
      settingPath = 'statuses';
      settingName = 'sale_statuses';
      propName = 'label';
    } else if (id == 'tags') {
      apiEndpoint = 'TAGS';
      getData = 'allTags';
      propName = 'name';
    } else if (id == 'profile_id') {
      apiEndpoint = 'PROFILES';
      getData = 'allProfiles';
      propName = 'name';
    } else if (id == 'pos_id') {
      apiEndpoint = 'POS';
      getData = 'allPOS';
      propName = 'name';
    } else if (id == 'location_type_id' ) {
      apiEndpoint = 'STOCK_LOCATION_TYPES';
      getData = 'allStockLocationTypes';
      propName = 'name';
    } else if (id == 'role_id' ) {
      apiEndpoint = 'USER_ROLES';
      getData = 'allUserRoles';
      propName = 'name';
    } else if (id == 'invoice_status') {
      settingPath = 'statuses';
      settingName = 'invoice_statuses';
      propName = 'label';
    } else if (id == 'quotation_status') {
      settingPath = 'statuses';
      settingName = 'quotation_statuses';
      propName = 'label';
    } else if (id == 'payment_status') {
      settingPath = 'statuses';
      settingName = 'payment_statuses';
      propName = 'label';
    } else if (id == 'payment_method') {
      settingPath = '';
      settingName = 'payment_methods';
      propName = 'label';
    } else if (id == 'payment_provider') {
      settingPath = 'types';
      settingName = 'payment_providers';
      propName = 'label';
    } else if (id == 'customer_status') {
      settingPath = 'statuses';
      settingName = 'customer_statuses';
      propName = 'label';
    } else if (id == 'country_code') {
      settingPath = '';
      settingName = 'countries';
      propName = 'label';
    } else if (id == 'product_status') {
      settingPath = 'statuses';
      settingName = 'product_statuses';
      propName = 'label';
    } else if (id == 'profile_status') {
      settingPath = 'statuses';
      settingName = 'profile_statuses';
      propName = 'label';
    } else if (id == 'language') {
      settingPath = '';
      settingName = 'languages';
      propName = 'label';
    } else if (id == 'connection_status') {
      settingPath = 'statuses';
      settingName = 'connection_statuses';
      propName = 'label';
    } else if (id == 'price_status') {
      settingPath = 'statuses';
      settingName = 'price_statuses';
      propName = 'label';
    } else if (id == 'user_status') {
      settingPath = 'statuses';
      settingName = 'user_statuses';
      propName = 'label';
    } else if (id == 'trigger_status') {
      settingPath = 'statuses';
      settingName = 'trigger_statuses';
      propName = 'label';
    } else if (id == 'tag_status') {
      settingPath = 'statuses';
      settingName = 'tag_statuses';
      propName = 'label';
    } else if (id == 'shipment_status') {
      settingPath = 'statuses';
      settingName = 'shipment_statuses';
      propName = 'label';
    } else if (id == 'specification_status') {
      settingPath = 'statuses';
      settingName = 'specification_statuses';
      propName = 'label';
    } else if (id == 'supplier_status') {
      settingPath = 'statuses';
      settingName = 'supplier_statuses';
      propName = 'label';
    } else if (id == 'automation_status') {
      settingPath = 'statuses';
      settingName = 'automation_statuses';
      propName = 'label';
    } else if (id == 'discount_status') {
      settingPath = 'statuses';
      settingName = 'discount_statuses';
      propName = 'label';
    } else if (id == 'discount_group_status') {
      settingPath = 'statuses';
      settingName = 'discount_group_statuses';
      propName = 'label';
    } else if (id == 'media_type') {
      settingPath = 'types';
      settingName = 'media_types';
      propName = 'label';
    } else if (id == 'product_type') {
      settingPath = 'types';
      settingName = 'product_types';
      propName = 'label';
    } else if (id == 'discount_rule_status') {
      settingPath = 'statuses';
      settingName = 'discount_rule_statuses';
      propName = 'label';
    } else if (id == 'specification_type') {
      settingPath = 'types';
      settingName = 'specification_types';
      propName = 'label';
    } else if (id == 'description_type') {
      settingPath = 'types';
      settingName = 'product_description_types';
      propName = 'label';
    } else if (id == 'connection_type') {
      settingPath = 'types';
      settingName = 'connection_types';
      propName = 'label';
    } else if (id == 'connection_category') {
      settingPath = 'types';
      settingName = 'connection_categories';
      propName = 'label';
    } else if (id == 'email_status') {
      settingPath = 'statuses';
      settingName = 'email_statuses';
      propName = 'label';
    } else if (id == 'role_status') {
      settingPath = 'statuses';
      settingName = 'role_statuses';
      propName = 'label';
    } else if (id == 'stock_type') {
      settingPath = 'types';
      settingName = 'stock_types';
      propName = 'label';
    } else if (id == 'tag_category') {
      settingPath = 'types';
      settingName = 'tag_categories';
      propName = 'label';
    } else if (id == 'automation_type') {
      settingPath = 'types';
      settingName = 'automation_types';
      propName = 'label';
    } else if (id == 'cash_log_type') {
      settingPath = 'types';
      settingName = 'cash_log_types';
      propName = 'label';
    } else if (id == 'label_type') {
      settingPath = 'types';
      settingName = 'label_types';
      propName = 'label';
    } else if (id == 'label_status') {
      settingPath = 'statuses';
      settingName = 'label_statuses';
      propName = 'label';
    } else if (id == 'delivery_method') {
      settingPath = 'types';
      settingName = 'delivery_methods';
      propName = 'label';
    } else if (id == 'return_status') {
      settingPath = 'statuses';
      settingName = 'return_statuses';
      propName = 'label';
    } else if (id == 'subscription_status') {
      settingPath = 'statuses';
      settingName = 'subscription_statuses';
      propName = 'label';
    } else if (id == 'tag_status') {
      settingPath = 'statuses';
      settingName = 'tag_statuses';
      propName = 'label';
    }

    if (settingPath || settingPath == '') {
      options = settingPath ? store.getters.appSettings[settingPath][settingName] : store.getters.appSettings[settingName];
    } else {
      options = await store.dispatch(Actions[apiEndpoint]).then(() => {
        return store.getters[getData].items;
      })
    }

    for (let i = 0; i < items.length; i++) { 
      if (items[i].count > 0) {
      const label = options ? options.find( ({ id }) => id === items[i].key ) as any || '' : ''
      const object = { value: items[i].key, label: (label != undefined) ? label[propName] : '', count: items[i].count }
      filterOptions.push(object);
      }
    }
    return filterOptions
  }

  const createFilters = () => 
    Promise.all(getComponentFilter.value
      .filter(item => item?.items?.some((obj) => obj.count))
      .map(({id, label, items}) => 
        createFilterOptions(id, items)
          .then(options => ({
            defaultValue: createDefaultValue(id), 
            id,
            // value: 'none',
            label,
            options
          }))
      )
    )

  const createDefaultValue = (id) => {
    const data = store.getters[filterInit.value.dispatchActions.init]?.options 
    let defaultValue;
    if (data != undefined) {
      for (const [key, value] of Object.entries(data)) {
        if (key === id) {
          defaultValue = value;
        }
      }
    } 

    return defaultValue ? defaultValue : null
  }

  const updateFilter = (id, value) => {
    filterLoading.value = true

    let string = null as any;
    if (value.length > 0) {
      filterString.value[id] = value
    } else {
      delete filterString.value[id]
    }

    if (Object.keys(filterString.value).length !== 0) {
      for(const filterMethod in filterString.value) {
        if (string == null) {
          string = '';
        }
        string += '&filter='+ filterMethod + ':=:'+ filterString.value[filterMethod].join(',')
      }
    }
    submitFilter(string, filterString.value)
  }

  const submitFilter = async (string, options) => {
    store.commit(Mutations[filterInit.value.dispatchActions.save], {string: string, options: options});
    emit('reload')
    filterLoading.value = false
  }

  const resetFilter = async () => {
    submitFilter(null, [])
    filterString.value = {}
    initFilter(filterInit.value)
  }
  
  return {
    initFilter,
    filterData,
    updateFilter,
    resetFilter,
    filterLoading
  }
}